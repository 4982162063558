.content-page {
    padding: 0 12px 65px !important;
}

.absolute-y-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.absolute-x-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.absolute-xy-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

.feedback {
    padding-left: .75rem;
    font-size: .75rem;
    color: #fa5c7c
}

#bulk-test-table .btn-link{
    padding: 0
}

#info-box .btn-primary{
    box-shadow: none;
    border: none
}

#info-box .dropdown-menu {
    top: 7px !important
}

#simulation-result-setup #dropdown-basic {
    box-shadow: unset;
    max-width: 100%;
    white-space: normal;
    /*word-wrap: break-word;*/
}

@media (min-width: 1200px){
    #version-info-content {
        display: flex;
    }

    #version-info-content > div{
        padding-right: 0;
        padding-left: 0;
    }
}

.apexcharts-canvas {
    margin: auto
}

.h-100px {
    height: 100px !important;
}

.w-110px {
    width: 110px !important;
}

.font-12 {
    font-size: 12px!important;
}

.font-24 {
    font-size: 24px!important;
}

.preloader {
    position: fixed !important;
    top: 0;
    left: 260px;
    right: 0;
    bottom: 0;
    /*background-color: #f1f3fa;*/
    z-index: 9999;
}

.table-fixed {
    table-layout: fixed;
}

.ellipsis {
    /*left: 0;*/
    /*right: 110px;*/
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.toast-progress {
    -webkit-transition: width 2.6s ease-in;
    -o-transition: width 2.6s ease-in;
    transition: width 2.6s ease-in;
    background-color: rgba(0,0,0,0.2)
}

.vh-content {
    height: calc(100vh - 61px);
}

.cursor-pointer {
    cursor: pointer;
}

.is-invalid-no-bg.is-invalid {
    background-image: none !important;
    padding-right: 0.9rem !important;
}

.search-input {
    padding-right: 1.7rem !important;;
}
.search-input-clear {
    display: flex;
    position: absolute;
    right: 50px;
    top: 0;
    bottom: 0;
    height: 100%;
    margin: auto;
    cursor: pointer;
    color: #a9a9a9;
    font-size: 18px;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    z-index: 10;
}
