
.widget-btn {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.widget {
  position: fixed;
  right: 30px;
  z-index: 1001;
  height: 570px;
  width: 640px;
  border: 0.5px solid #aaaaaa;
  box-shadow: 0 0 25px 0 rgba(154, 161, 171, 0.5);
  overflow-y: auto;
  padding: 20px;
  background-color: white;
  border-radius: 7px;
}